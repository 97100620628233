@import "../../scss/index.scss";

.header-main-container {
    padding: 30px;

    .header-main-text {
        color: $charcoal;
        @include typography("medium", 32px);

        .header-company-name {
            @include typography("bold", 36px);
        }
    }

    .header-tagline-text {
        color: $charcoal;
        @include typography("regular", 16px);
        line-height: 20.16px;
    }

}

@media screen and (max-width: 768px) {
    .header-main-container {
        .header-main-text {
            @include typography("medium", 20px);
    
            .header-company-name {
                @include typography("medium", 32px);
            }
        }
    
        .header-tagline-text {
            @include typography("regular", 14px);
        }
    
    }
}