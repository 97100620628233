.loader-container{
  display:flex;
  justify-content: center;
  align-items: center;
 width:100%;
 height:100vh;
 
}
.login-loader-container {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 0px;
    opacity: 1;
    width:333px;
    height:333px;
    border-radius: 20px;
    background-color: white;
  }
  
  .circle-loader {
    display: flex;
    gap: 10px; 
  }
  
  .circle {
    width: 32px;
    height: 32px;
    background-color: #009587;
    border-radius: 50%;
    animation: bounce 1.5s infinite;
  }
  
  .circle:nth-child(2) {
    animation-delay: 0.5s;
  }
  
  .circle:nth-child(3) {
    animation-delay: 1s; 
  }
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0); 
    }
    30% {
      transform: translateY(-60px); /* Move up */
    }
    60% {
      transform: translateY(0); /* Move back to middle */
    }
    
    10% {
      transform: translateY(60px); /* Slight move up for finishing bounce effect */
    }
  }
  