$white: #ffffff;
$black: #000000;
$error: #ba0000;
$success: #76ca66;
$gray:#808080;
$charcoal:#464646;
$crimson:#e12c36;
$monarch:#ff8d23;
$denim:#0079ea;
$benchmark:#009587;
$cotton_ball:#f2f8fe;
$break_line:#648282;
$light_gray:#7E7E7E;
$input_border:#B8B8B8;
$button_orange:#E8273E
