@import "../../scss/index.scss";

.main-container{
    background-color: $cotton_ball;
    height: 100vh;
    overflow: auto;
}
@media screen and (max-width: 768px) {
.mobile-stepper{
display: flex;
background-color: $white;
margin-bottom: 20px;
}
}